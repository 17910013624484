import {motion} from "framer-motion"
import {useTranslation} from "react-i18next";

export const OfferCard = ({css, offer, showDetail}) => {
    const {t} = useTranslation()
    return (
        <>
            <motion.div initial={{opacity: 0, scale: 0.5}}
                        animate={{opacity: 1, scale: 1}}
                        transition={{duration: 0.5}}
                        className={`h-[300px] ${offer.css} cursor-pointer transition duration-0 hover:duration-150`}
                        onClick={() => showDetail(offer)}>
                <div className={'w-[100%] flex justify-center h-[100px]'}>
                    <div className={'pt-[33px] pb-[29px] '}>
                        <img src={offer.image} alt={t(`${offer.name}`)} className={'h-[50px] '}/>
                    </div>
                </div>
                <div className={'h-[50px] w-full flex justify-center'}>
                    <p className={'font-semibold text-[20px]'}>{t(`${offer.name}`)}</p>
                </div>
                <div className={'h-[150px]'}>
                    <div className={'px-[10px]'}>
                        <p className={'text-center'}>
                            {t(`${offer.caption}`)}
                        </p>
                    </div>
                </div>

            </motion.div>
        </>
    )
}