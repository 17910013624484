import {useTranslation} from "react-i18next";

export const OfferDetail = ({offer}) => {
    const {t} = useTranslation()
    return (
        <>
            <div className={'w-full bg-[#fff]'}>
                <div className={'block lg:flex w-full'}>
                    <div className={'w-full lg:w-[20%] flex lg:block justify-center mb-[20px] lg:mb-[0px]'}>
                        <div
                            className={`h-[110px] w-[110px] relative rounded-full bg-white drop-shadow-lg border-[2px] ${offer.css_single}`}>
                            <img src={offer.image} alt={t(`${offer.name}`)}
                                 className={'h-[50px] absolute top-[33px] left-[29px]'}/>
                        </div>
                    </div>
                    <div className={'w-full lg:w-[80%]'}>
                        <div
                            className={'h-full bg-white drop-shadow-lg px-[20px] py-[10px] text-center lg:text-justify'}>
                            <p>
                                {t(`${offer.description}`)}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}