import {Header} from "../components/layouts/Header";
import {Footer} from "../components/layouts/Footer";
import {Hero} from "../components/section/Hero";
import {About} from "../components/section/About";
import {Expertises} from "../components/section/Expertises";
import {Projects} from "../components/project/Projects";
// import {Contact} from "../components/section/Contact";
import {useEffect} from "react";
// import {AiOutlineShareAlt} from "react-icons/ai";


export const Home = () => {
    useEffect(() => {
    }, [])
    return (
        <>

            <Header/>
            <Hero/>
            <About/>
            <Projects/>
            <Expertises/>
            <Footer/>
        </>
    )
}