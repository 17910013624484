import cprs_1 from "../assets/images/porfolio/cprs_1.PNG";
import fds from "../assets/images/porfolio/fds_1.PNG";
import schoolsify from "../assets/images/porfolio/schoolsify.PNG";

const ProjectList = [
    {
        'title': 'CPRS',
        'image': cprs_1,
        'images': [cprs_1],
        'section': 'frontend',
        'caption': 'project.cprs.caption',
        'description': 'CPRS is a comprehensive online platform exclusively developed for film distributors. Its primary purpose is to streamline and facilitate the process of organizing and coordinating the distribution of films across numerous cinemas. By providing efficient arrangement capabilities and timely notifications, CPRS aims to enhance the distribution workflow for film distributors.'
    },
    {
        'title': 'Film Distribution System',
        'image': fds,
        'images': [fds],
        'section': 'frontend',
        'caption': 'project.fds.caption',
        'description': 'CPRS is a comprehensive online platform exclusively developed for film distributors. Its primary purpose is to streamline and facilitate the process of organizing and coordinating the distribution of films across numerous cinemas. By providing efficient arrangement capabilities and timely notifications, CPRS aims to enhance the distribution workflow for film distributors.'
    },
    {
        'title': 'Schoolsify',
        'image': schoolsify,
        'images': [schoolsify],
        'section': 'frontend',
        'caption': 'project.schoolsify.caption',
        'description': 'CPRS is a comprehensive online platform exclusively developed for film distributors. Its primary purpose is to streamline and facilitate the process of organizing and coordinating the distribution of films across numerous cinemas. By providing efficient arrangement capabilities and timely notifications, CPRS aims to enhance the distribution workflow for film distributors.'
    },
]

export {
    ProjectList
}