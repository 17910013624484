import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter} from "react-router-dom"
import reportWebVitals from './reportWebVitals';
import {Provider} from 'react-redux'
import {store} from "./store";
import {PersistGate} from "redux-persist/integration/react";
import {persistStore} from "redux-persist";
import './i18n';
import {Suspense} from 'react';

const persist = persistStore(store)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <PersistGate persistor={persist}>
                    <Suspense fallback="...loading">
                        <App/>
                    </Suspense>
                </PersistGate>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
