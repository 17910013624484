import code from "./../../assets/images/code.jpg"
import {SpotIcon} from "../reusable/SpotIcon";
import python from "./../../assets/images/expertise/python.png"
import vue from "./../../assets/images/expertise/vue.png"
import aws from "./../../assets/images/expertise/aws-ec2.svg"
import docker from "./../../assets/images/expertise/docker.png"
import {useTranslation} from 'react-i18next';
import {SlSocialInstagram} from "react-icons/sl";
import {FiMail} from "react-icons/fi";
import {BsTwitter, BsLinkedin, BsGithub} from "react-icons/bs";

export const Hero = () => {
    const {t} = useTranslation();
    return (
        <>
            <div className={'h-[700px] laptop:h-[700px] desktop:h-[650px] w-full bg-white'}>

                <div className={'w-[90%] laptop:[90%] desktop:w-[80%] mx-auto laptop:py-[75px] desktop:py-[20px]'}>
                    <div className={'block laptop:flex desktop:flex gap-5'}>
                        <div className={'w-full lg:w-[50%] py-[50px] lg:py-[150px]'}>
                            <div>
                                <h4 className={'text-[31px] laptop:text-[31px] desktop:text-[55px] '}><span
                                    className={'font-bold text-[#5B38E3]'}>{t('hero.hi')}</span><span
                                    className={'text-gray-200'}>,</span></h4>
                            </div>
                            <div>
                                <p className={'text-[27px] laptop:text-[27px] desktop:text-[50px]  font-bold'}><strong
                                    className={' text-[#5B38E3]'}>{t('hero.i_am')}</strong> Adenitire Ayomikun</p>
                            </div>
                            <div className={''}>
                                <p>
                                    {t('hero.about')}
                                </p>
                            </div>
                            <div className={'mt-[51px]'}>
                                <div className={'flex gap-8'}>
                                    <div className={'py-[12px]'}>
                                        <p className={'font-semibold'}>Contact: </p>
                                    </div>
                                    <div className={'w-full px-[10px]'}>
                                        <div className={'flex gap-2'}>
                                            <a href={'mailto:adenitiree@gmail.com'} target={'_blank'} rel="noreferrer"
                                               className={'h-[20px] w-[20px] p-[20px] shadow bg-white cursor-pointer relative rounded-full drop-shadow-[0px_4px_8px_0px_rgba(0,0,0,.12)]'}>
                                                <FiMail
                                                    className={'absolute left-[12px] top-[13px] font-bold text-[17px] text-[#5B38E3]'}/>
                                            </a>
                                            <a href={'https://github.com/Emizy'} target={'_blank'} rel="noreferrer"
                                               className={'h-[20px] w-[20px] p-[20px] shadow bg-white cursor-pointer relative rounded-full drop-shadow-[0px_4px_8px_0px_rgba(0,0,0,.12)]'}>
                                                <BsGithub
                                                    className={'absolute left-[12px] top-[13px] font-bold text-[17px] text-[#5B38E3]'}/>
                                            </a>
                                            <a href={'https://www.linkedin.com/in/adenitire-ayomikun/'} rel="noreferrer"
                                               target={'_blank'}
                                               className={'h-[20px] w-[20px] p-[20px] shadow bg-white cursor-pointer relative rounded-full drop-shadow-[0px_4px_8px_0px_rgba(0,0,0,.12)]'}>
                                                <BsLinkedin
                                                    className={'absolute left-[12px] top-[13px] font-bold text-[17px] text-[#5B38E3]'}/>
                                            </a>
                                            <a href={'https://twitter.com/i_emizy'} target={'_blank'} rel="noreferrer"
                                               className={'h-[20px] w-[20px] p-[20px] shadow cursor-pointer bg-white relative rounded-full drop-shadow-[0px_4px_8px_0px_rgba(0,0,0,.12)]'}>
                                                <BsTwitter
                                                    className={'absolute left-[12px] top-[13px] font-bold text-[17px] text-[#5B38E3]'}/>
                                            </a>
                                            <a href={'https://instagram.com/adenitire_ayomikun'} rel="noreferrer"
                                               target={'_blank'}
                                               className={'h-[20px] w-[20px] p-[20px] shadow cursor-pointer bg-white relative rounded-full drop-shadow-[0px_4px_8px_0px_rgba(0,0,0,.12)]'}>
                                                <SlSocialInstagram
                                                    className={'absolute left-[12px] top-[13px] font-bold text-[17px] text-[#5B38E3]'}/>
                                            </a>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div className={'w-full lg:w-[50%]'}>
                            <div className={'relative top-[0px] lg:top-[79px]'}>
                                <div className={'absolute z-[20] ease-in duration-300 cursor-pointer -rotate-45'}>
                                    <SpotIcon item={{icon: python, name: 'python'}}/>
                                </div>
                                <div
                                    className={'absolute z-[20] right-[40px] laptop:top-[-44px] desktop:top-[0px] ease-in duration-300 cursor-pointer -rotate-45'}>
                                    <SpotIcon item={{icon: docker, name: 'docker'}}/>
                                </div>
                                <div
                                    className={'absolute z-[20] top-[197px] laptop:top-[270px] desktop:top-[420px] left-[3px] lg:left-[40px] ease-in duration-300 cursor-pointer -rtate-45'}>
                                    <SpotIcon item={{icon: aws, name: 'aws'}}/>
                                </div>
                                <div
                                    className={'absolute z-[20] top-[197px] laptop:top-[270px] desktop:top-[420px] right-[40px] ease-in duration-300 cursor-pointer -rtate-45'}>
                                    <SpotIcon item={{icon: vue, name: 'vue'}}/>
                                </div>
                                <div
                                    className={'absolute z-[10] left-[23px] desktop:left-[57px] top-[1px] flex justify-center'}>
                                    <img alt={'software development'} src={code}
                                         className={'small:w-[250px] tablet:w-[250px] laptop:w-[440px] desktop:w-full'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}