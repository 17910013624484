import appState from './appState'
import {SET_LOCALE_FLAG, SET_LOCALE} from "./actionTypes";

export const appReducer = (state = appState, action) => {
    switch (action.type) {
        case SET_LOCALE_FLAG:
            return {
                ...state,
                flag: action.payload.flag,
            }
        case SET_LOCALE:
            window.localStorage.setItem('locale', action.payload.locale)
            return {
                ...state,
                locale: action.payload.locale,
            }
        default:
            return state
    }
}