import {SET_LOCALE_FLAG} from "./actionTypes";

const setLocaleFlag = (payload) => {
    return {
        type: SET_LOCALE_FLAG,
        payload
    };
};



export {setLocaleFlag};