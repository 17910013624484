import {OfferCard} from "../reusable/OfferCard";
import {useState} from "react";
import {Modal} from "../reusable/Modal";
import code from "./../../assets/images/ui.png"
import devops from "./../../assets/images/devops.png"
import backend from "./../../assets/images/backend.png"
import {OfferDetail} from "../reusable/OfferDetail";
import {useTranslation} from "react-i18next";


export const About = () => {
    const {t} = useTranslation();
    const [offers, setOffers] = useState([
        {
            "image": code,
            "name": 'about.frontend.name',
            "css": "bg-lime-300",
            "css_single": "border-lime-300",
            "caption": 'about.frontend.caption',
            "description": 'about.frontend.description'
        },
        {
            "image": backend,
            "name": "about.backend.name",
            "css": "bg-green-400",
            "css_single": "border-green-400",
            "caption": "about.backend.caption",
            "description": "about.backend.description"
        },
        {
            "image": devops,
            "name": "about.devops.name",
            "css": "bg-indigo-600",
            "css_single": "border-indigo-400",
            "caption": "about.devops.caption",
            "description": "about.devops.description"
        },
    ])
    const [offer, setOffer] = useState({})
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = (offer) => {
        setOffer(offer)
        setIsModalOpen(true);
    };
    const handleCancel = (status) => {
        setIsModalOpen(status);
    };
    return (
        <>
            <div className={'bg-[#202020] h-full w-full'} id={'about'}>
                <div className={'w-[95%] lg:w-[60%] mx-auto'}>
                    <div className={'w-full pt-[90px]'}>
                        <div className={'w-full flex justify-center py-[15px]'}>
                            <h4 className={'text-[#ffffff] text-[25px] font-bold'}>{t('about.title')}</h4>
                        </div>
                        <div className={'px-[10px] lg:px-[0px] py-[10px]'}>
                            <p className={'w-full text-center font-semibold  text-[14px] lg:text-[17px] text-[#ffffff]'}>
                                {t('about.description')}
                            </p>
                        </div>
                    </div>
                    <div className={'py-[60px] w-full'}>
                        <div className={'flex gap-6'}>
                            <div className={'w-[50%] flex justify-center lg:justify-end'}>
                                <div className={'flex w-full lg:justify-end'}>
                                    <div className={'w-[25%] lg:w-[12%] flex justify-end'}>
                                        <h5 className={'text-[40px] lg:text-[60px] text-left font-bold text-[#f7d046] pr-[5px]'}>5</h5>
                                    </div>
                                    <div className={'w-[70%] lg:w-[30%]'}>
                                        <p className={'lg:text-[20px] leading-[18px] lg:leading-[28px] font-[600] pt-[11px] lg:pt-[18px] text-[#747474]'}>{t('about.year_of_experience')}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={'w-[50%] flex justify-start'}>
                                <div className={'flex w-full'}>
                                    <div className={'w-[27%] flex justify-end'}>
                                        <h5 className={'text-[40px] lg:text-[60px] font-bold text-[#f7d046] pr-[5px]'}>10+</h5>
                                    </div>
                                    <div className={'w-[70%] lg:w-[30%]'}>
                                        <p className={'lg:text-[20px] leading-[28px] font-[600] pt-[15px] lg:pt-[30px] text-[#747474]'}>{t('about.project_done')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'pb-[60px] w-full'}>
                        <div className={'flex justify-center'}>
                            <h5 className={'text-[#ffffff] text-[25px] font-bold'}>
                                {t('about.services')}
                            </h5>
                        </div>
                        <div className={'w-full pt-[20px] px-[30px]'}>
                            <div className={'grid grid-cols-1 lg:grid-cols-3 gap-6'}>
                                {
                                    offers.map((item, key) => {
                                        return <OfferCard css={item.css} offer={item} key={key}
                                                          showDetail={showModal}/>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isModalOpen && <Modal title={t(`${offer.name}`)} width={'w-11/12 md:w-[50%]'} setIsOpen={handleCancel}
                                   htmlBody={<OfferDetail offer={offer}/>}/>}
        </>
    )
}