import {AiOutlineShareAlt} from "react-icons/ai";
import {IoCallOutline, IoMenuSharp} from "react-icons/io5";
import {RxCross1} from "react-icons/rx";
import {useState} from "react";
import {useTranslation} from 'react-i18next';
import logo from './../../assets/images/adenitire_ayomikun_full_white.png'
import {Locale} from "../reusable/Locale";
import {useDispatch, useSelector} from 'react-redux'
import {setLocaleFlag} from "../../actionsReducer/mutations"
import {NavLink} from "react-router-dom";

export const Header = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const {flag} = useSelector((state) => state.global)
    const [isOpen, setIsOpen] = useState(false)
    const setOpen = () => {
        let open = !isOpen
        setIsOpen(open)
    }
    const onCloseLocale = () => {
        dispatch(setLocaleFlag({
            flag: 'close',
        }))
    }

    return (
        <>
            {isOpen === true && <div className={"absolute  inset-0 z-[600]"} onClick={() => setIsOpen(false)}/>}
            {flag === 'open' && <div className={'absolute  inset-0 z-[0]'} onClick={() => onCloseLocale()}/>}
            <div className={'w-full bg-white h-[75px] hidden lg:block sticky'}>
                <div className={'w-full h-full flex relative'}>
                    <div className={'w-[20%]'}>
                        <div className={'h-[34px] mx-auto flex justify-center py-[25px]'}>
                            <NavLink to={'/'}>
                                 <img src={logo} alt={'adenitire ayomikun'} className={'h-[34px]'}/>
                            </NavLink>

                        </div>
                    </div>
                    <div className={'w-[50%] mx-auto'}>
                        <div className={'mx-auto flex justify-center'}>
                            <ul className={'flex justify-around'}>
                                <li className={'px-[40px] py-[25px]'}><NavLink to={'/'}>{t('header.home')}</NavLink></li>
                                <li className={'px-[40px] py-[25px]'}><a href={"#about"}>{t('header.about')}</a></li>
                                <li className={'px-[40px] py-[25px]'}><a href={"#project"}>{t('header.project')}</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={'w-[30%] relative'}>
                        <div className={'py-[25px] flex justify-center relative'}>
                            <ul className={'flex'}>
                                <li className={'px-[40px]'}>
                                    <div className={'font-[500]'}>
                                        <Locale/>
                                    </div>
                                </li>
                                <li className={'px-[40px]'}>
                                    <span>
                                    <AiOutlineShareAlt
                                        className={'cursor-pointer text-[21px] font-semibold text-[#5B38E3]'}/>
                                </span>
                                </li>
                                <li className={'px-[40px]'}>
                                    <span>
                                    <IoCallOutline
                                        className={'cursor-pointer text-[21px] font-semibold text-[#5B38E3]'}/>
                                </span>
                                </li>
                            </ul>

                        </div>

                    </div>

                </div>
            </div>
            <div className={'w-full bg-white h-[60px] lg:hidden block relative'}>
                <div className={'w-full h-full flex'}>
                    <div className={'w-[50%]'}>
                        <div className={'h-[30px] py-[15px]'}>
                            <img src={logo} alt={'adenitire ayomikun'} className={'h-[24px] mt-[10px] pl-[18px]'}/>
                        </div>
                    </div>
                    <div className={'w-[50%] mx-auto'}>
                        <div className={'mx-auto flex justify-end px-[20px] py-[15px]'}>
                            <div className={'flex pr-[20px] gap-4'}>
                                <div
                                    className={'h-[20px] w-[20px] p-[20px] shadow bg-white relative rounded-full drop-shadow-[0px_4px_8px_0px_rgba(0,0,0,.12)]'}>
                                    <div className={'absolute top-[9px] left-[10px] font-[500]'}>
                                        <Locale/>
                                    </div>
                                </div>
                                <div
                                    className={'h-[20px] w-[20px] p-[20px] shadow bg-white relative rounded-full drop-shadow-[0px_4px_8px_0px_rgba(0,0,0,.12)]'}>
                                    <AiOutlineShareAlt
                                        className={'absolute left-[12px] top-[13px] font-bold text-[17px] text-[#5B38E3]'}/>
                                </div>
                            </div>
                            <div onClick={setOpen}
                                 className={'h-[20px] w-[20px] p-[20px] cursor-pointer shadow bg-white relative rounded-full drop-shadow-[0px_4px_8px_0px_rgba(0,0,0,.12)]'}>
                                {isOpen === false && <IoMenuSharp
                                    className={'absolute left-[12px] top-[13px] font-bold text-[17px] text-[#5B38E3]'}/>}
                                {isOpen === true && <RxCross1
                                    className={'absolute left-[12px] top-[13px] font-bold text-[17px] text-[#5B38E3]'}/>}
                            </div>
                        </div>
                    </div>
                </div>
                {isOpen === true &&

                <div
                    className={'bg-white drop-shadow-lg top-[65px] border z-[999] rounded-[5px] min-h-[130px] w-[60%] absolute right-[15px] p-[15px]'}>
                    <ul>
                        <li className={'pb-[10px]'}>{t('header.home')}</li>
                        <li className={'pb-[10px]'}><a href={"#about"}
                                                       onClick={() => setIsOpen(false)}>{t('header.about')}</a></li>
                        <li className={'pb-[10px]'}><a href={"#project"}
                                                       onClick={() => setIsOpen(false)}>{t('header.project')}</a></li>
                        {/*<li className={'pb-[10px]'}><a href={"#contact"}*/}
                        {/*                               onClick={() => setIsOpen(false)}>{t('header.contact')}</a></li>*/}
                    </ul>
                </div>
                }

            </div>
        </>
    )
}