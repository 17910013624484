import {useTranslation} from "react-i18next";

export const Project = ({project}) => {
    const {t} = useTranslation()
    return (
        <>
            <div className={'h-[420px] w-full bg-white hover:shadow drop-shadow-lg rounded-lg cursor-pointer'}>
                <div className={'h-200px'}>
                    <img src={project.image} alt={'cprs'} className={'object-cover h-[300px]'}/>
                </div>
                <div className={'h-[] py-[15px] px-[15px]'}>
                    <div className={'w-full pb-[2px]'}>
                        <p className={'font-bold text-[15px]'}>{project.title}</p>
                    </div>
                    <div className={'w-full pb-[10px]'}>
                        <p className={'text-sm'}>
                            {t(`${project.caption}`)}
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
}