import logo from "../../assets/images/adenitire_ayomikun_full_white.png";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";

export const Footer = () => {
    const {t} = useTranslation()
    return (
        <>
            <div className={'mt-[100px]'}>

                <div className={'w-[90%] h-[1px] my-[10px] bg-[#000] mx-auto'}/>
                <div className={'w-full bg-white h-[60px] '}>
                    <div className={'w-[90%] mx-auto h-full flex justify-between'}>
                        <div className={'w-[50%] lg:w-[20%]'}>
                            <div
                                className={'h-[34px] mx-auto flex justify-start lg:justify-center py-[25px] pl-[10px]'}>
                                <img src={logo} alt={'adenitire ayomikun'} className={'h-[23px] lg:h-[34px]'}/>
                            </div>
                        </div>
                        <div className={'w-[50%] hidden lg:block'}>
                            <div className={'mx-auto flex justify-end'}>
                                <ul className={'flex justify-around'}>
                                    <li className={'px-[40px] py-[25px]'}><NavLink to={'/'}>{t('header.home')}</NavLink>
                                    </li>
                                    <li className={'px-[40px] py-[25px]'}><a href={'#about'}>{t('header.about')}</a>
                                    </li>
                                    <li className={'px-[40px] py-[25px]'}><a href={'#project'}>{t('header.project')}</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/*<div className={'w-[50%] lg:w-[30%]'}>*/}
                        {/*    <div className={'py-[25px] flex justify-end pr-[1] lg:justify-center'}>*/}
                        {/*        <ul className={'flex'}>*/}
                        {/*            <li className={'px-[40px]'}>*/}
                        {/*            <span className={'cursor-pointer text-[#5B38E3]'}>*/}
                        {/*            /!*<Locale/>*!/*/}
                        {/*        </span>*/}
                        {/*            </li>*/}
                        {/*        </ul>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </>
    )
}