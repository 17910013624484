import {useEffect, useState} from "react";
import {ProjectList} from "../../utility/constant"
import {Project} from "./Project";

export const Projects = () => {
    const [tab, setTab] = useState('all')
    const [projects, setProjects] = useState([])
    const onChangeTab = (tb) => {
        let filter = ProjectList
        if (tb !== 'all') {
            filter = ProjectList.filter(item => item.section === tb)
        }
        setProjects(filter)
        setTab(tb)
    }
    useEffect(() => {
        setProjects(ProjectList)
    }, [])
    return (
        <>
            <div className={'w-full h-full pb-[60px] pt-[60px]'} id={'project'}>
                <div className={'w-[90%] lg:w-[80%] mx-auto'}>
                    <div className={'w-full flex justify-center py-[10px]'}>
                        <h5 className={'text-[25px] font-semibold'}>Related Works</h5>
                    </div>
                    <div className={'w-[50%] mx-auto py-[20px] hidden'}>
                        <div className="flex justify-center mb-3 gap-3 ">
                            <span onClick={() => onChangeTab('all')}
                                  className={`text-base border ${tab === 'all' ? 'border-[#5B38E3] text-[#5B38E3] shadow-lg' : ''} py-[5px] px-[10px] rounded bg-white cursor-pointer px-[20px] font-semibold text-gray-900 dashboard-head-size`}>All</span>
                            <span onClick={() => onChangeTab('frontend')}
                                  className={`text-base border ${tab === 'frontend' ? 'border-[#5B38E3] text-[#5B38E3] shadow-lg' : ''} py-[5px] px-[10px] bg-white cursor-pointer rounded px-[20px] font-semibold text-gray-900 dashboard-head-size`}>Frontend</span>
                            <span onClick={() => onChangeTab('backend')}
                                  className={`${tab === 'backend' ? 'border-[#5B38E3] text-[#5B38E3] shadow-lg' : ''} text-base border py-[5px] px-[10px] bg-white cursor-pointer rounded px-[20px] font-semibold text-gray-900 dashboard-head-size`}>Backend</span>
                            <span onClick={() => onChangeTab('devops')}
                                  className={`${tab === 'devops' ? 'border-[#5B38E3] text-[#5B38E3] shadow-lg' : ''} text-base border py-[5px] px-[10px] bg-white cursor-pointer rounded px-[20px] font-semibold text-gray-900 dashboard-head-size`}>DevOps</span>
                        </div>
                    </div>
                    <div className={'w-full'}>
                        <div className={'grid grid-cols-1 lg:grid-cols-3 gap-3'}>
                            {
                                projects.map((item, key) => {
                                    return <Project project={item} key={key}/>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}