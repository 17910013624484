export const Expertise = ({tool}) => {
    return (
        <>
            <div className={'mb-[10px] lg:mb-[0px] flex bg-white drop-shadow-md border-[#fff] shadow h-[50px] border w-[196.6px] rounded-md'}>
                <div className={'w-[30%]'}>
                    <div className={'flex justify-center h-full'}>
                        <img src={tool.image} alt={tool.name} className={'h-[20px] my-[16px] px-[5px]'}/>
                    </div>
                </div>
                <div className={'w-[70%]'}>
                    <div className={'w-full'}>
                        <p className={'font-semibold text-left text-[15px] py-[15px]'}>{tool.name}</p>
                    </div>
                </div>
            </div>
        </>
    )
}