import {Expertise} from "../reusable/Expertise";
import {useState} from "react";
import python from "./../../assets/images/expertise/python.png"
import reactjs from "./../../assets/images/expertise/react.png"
import vuejs from "./../../assets/images/expertise/vue.png"
import tailwindcss from "./../../assets/images/expertise/tailwindcss.png"
import html from "./../../assets/images/expertise/html.png"
import django from "./../../assets/images/expertise/django.png"
import aws_ec2 from "./../../assets/images/expertise/aws-ec2.svg"
import aws_route from "./../../assets/images/expertise/aws-route53.svg"
import docker from "./../../assets/images/expertise/docker.png"
import laravel from "./../../assets/images/expertise/laravel.png"
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
import './../../assets/css/st.css'
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/css/grid";
import {Autoplay} from "swiper";

export const Expertises = () => {
    const [tools, _] = useState([
        {
            'image': python,
            'name': 'Python'
        },
        {
            'image': reactjs,
            'name': 'React Js'
        },
        {
            'image': vuejs,
            'name': 'Vue Js'
        },
        {
            'image': tailwindcss,
            'name': 'Tailwind css'
        },
        {
            'image': html,
            'name': 'Html'
        },
        {
            'image': django,
            'name': 'Django'
        },
        {
            'image': aws_ec2,
            'name': 'AWS EC2'
        },
        {
            'image': aws_route,
            'name': 'AWS ROUTE 53'
        },
        {
            'image': docker,
            'name': 'Docker'
        },
        {
            'image': laravel,
            'name': 'Laravel (PHP)'
        },
    ])
    return (
        <>
            <div className={'w-full bg-white mb-[100px] mt-[75px] mb-[200px] lg:mb-[200px] pb-[50px] h-[100px]'}
                 id={'expertise'}>
                <div className={'w-[90%] mx-auto py-[60px] shadow-lg border rounded-2xl px-[20px]'}>
                    <Swiper
                        slidesPerView={5}
                        spaceBetween={25}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            "@0.00": {
                                slidesPerView: 1,
                                spaceBetween: 10,
                            },
                            "@0.75": {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            "@1.00": {
                                slidesPerView: 4,
                                spaceBetween: 20,
                            },
                            "@1.50": {
                                slidesPerView: 5,
                                spaceBetween: 30,
                            },
                        }}
                        modules={[Autoplay]}
                        className="mySwiper gap-6"
                    >
                        {tools.map((tool, key) => (
                            <SwiperSlide key={`slider_0${key}`}>
                                <div className={'my-[5px]'}>
                                    <Expertise tool={tool}/>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </>
    )
}