import {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux'
import {setLocaleFlag} from "../../actionsReducer/mutations"
import {useTranslation} from 'react-i18next';

export const Locale = () => {
    const dispatch = useDispatch()
    const {flag} = useSelector((state) => state.global)
    const {i18n} = useTranslation();
    const [localeList, _] = useState({
        'en': 'EN',
        'fr': 'FR'
    })
    const [currentLocale, setCurrentLocale] = useState('en')
    const onOpen = () => {
        let is_open = 'open'
        if (flag === 'open') {
            is_open = 'close'
        }
        dispatch(setLocaleFlag({
            flag: is_open,
        }))
    }
    const setIsLocaleOpen = (locale) => {
        i18n.changeLanguage(locale)
        dispatch(setLocaleFlag({
            flag: 'close',
        }))
        setCurrentLocale(locale)
    }
    useEffect(() => {
        let locale = i18n.language ? i18n.language : 'en'
        if (!(locale in localeList)) {
            setIsLocaleOpen('en')
            setCurrentLocale('en')
        } else {
            setCurrentLocale(locale)
        }

    }, [])
    return (
        <>

            <div>
                <span className={'cursor-pointer text-[#5B38E3]'} onClick={() => onOpen()}>
                    {localeList[currentLocale]}
                                    </span>
            </div>
            {flag === 'open' && <div className={'absolute h-[100px] w-[90px] z-[999] mt-[5px]'}>
                <div
                    className={'bg-white drop-shadow-lg border rounded-[5px] min-h-[90px] w-full right-[15px] p-[15px]'}>

                    <ul className={'divide-y-1'}>
                        <li className={'pb-[10px] cursor-pointer'} onClick={() => setIsLocaleOpen('en')}>EN</li>
                        <li className={'pb-[10px] cursor-pointer'} onClick={() => setIsLocaleOpen('fr')}>FR</li>
                    </ul>
                </div>
            </div>}


        </>
    )
}